import React, { useState } from "react";
import InnerBanner from "../Components/InnerBanner";
import { Link } from "react-router-dom";

const GraphicDesign = () => {
  return (
    <>
      <InnerBanner title={"Graphic Design"} />
      <div className="container" >
      <div class="contentpart "><h2><span>Graphic Design</span> </h2><h2>1. Logo Design</h2><p><strong>Focus:</strong> Creating a unique and recognizable symbol or mark for a brand or company.</p><p><strong>Tools:</strong> Adobe Illustrator, CorelDRAW, Sketch.</p><p><strong>Examples:</strong> Corporate logos, product logos, personal brands.</p><h2>2. Brochure Design</h2><p><strong>Focus:</strong> Designing informative and visually appealing brochures for marketing or informational purposes.</p><p><strong>Tools:</strong> Adobe InDesign, Photoshop, Canva.</p><p><strong>Examples:</strong> Product brochures, service brochures, event brochures.</p><h2>3. Letterhead Design</h2><p><strong>Focus:</strong> Creating a formal and professional design for official business communication.</p><p><strong>Tools:</strong> Adobe Illustrator, Microsoft Word, Canva.</p><p><strong>Examples:</strong> Business letters, official documents, proposals.</p><h2>4. Pamphlet/Flyer Design</h2><p><strong>Focus:</strong> Designing short, attention-grabbing marketing materials to promote products, services, or events.</p><p><strong>Tools:</strong> Adobe Photoshop, Canva, InDesign.</p><p><strong>Examples:</strong> Event flyers, promotional pamphlets, political campaign materials.</p><h2>5. Website Design</h2><p><strong>Focus:</strong> Designing the layout, visual appearance, and user interface of websites.</p><p><strong>Tools:</strong> Figma, Adobe XD, Sketch, Webflow.</p><p><strong>Examples:</strong> Corporate websites, e-commerce platforms, personal portfolios.</p></div>
       
      </div>
    </>
  );
};

export default GraphicDesign;
