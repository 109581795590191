import React, { useState } from "react";
import InnerBanner from "../Components/InnerBanner";
import { Link } from "react-router-dom";

const ContentWriting = () => {
  
  return (
    <>
      <InnerBanner title={"Content Writing"} />
      <div className="container" >
        <div className="contentpart ">
          

          <section>
            <h2><span>Content Writing</span></h2>
            <p className="section-title text-secondary fs-0.9">
              1. Website Content Writing
            </p>
            <p>
              We craft clear, concise, and SEO-optimized website content that
              communicates your brand's message effectively and helps drive
              traffic to your site.
            </p>
            <p className="section-title text-secondary fs-0.9">
              2. Blog Writing
            </p>
            <p>
              Keep your audience informed and engaged with well-researched,
              insightful, and regularly updated blog posts that position you as
              an industry expert.
            </p>
            <p className="section-title text-secondary fs-0.9">
              3. SEO Copywriting
            </p>
            <p>
              Our SEO-focused copywriting helps your content rank higher on
              search engines, ensuring better visibility and more organic
              traffic to your website.
            </p>
            <p className="section-title text-secondary fs-0.9">
              4. Product Descriptions
            </p>
            <p>
              We create compelling and persuasive product descriptions that
              highlight features, benefits, and value, driving conversions and
              boosting sales.
            </p>
            <p className="section-title text-secondary fs-0.9">
              5. Social Media Content
            </p>
            <p>
              Engage your followers and grow your social media presence with
              shareable, on-brand content tailored to platforms like Facebook,
              Instagram, LinkedIn, and Twitter.
            </p>
            <p className="section-title text-secondary fs-0.9">
              6. Email Marketing Content
            </p>
            <p>
              Get expertly written email campaigns that grab attention, drive
              clicks, and convert readers into loyal customers.
            </p>
            <p className="section-title text-secondary fs-0.9">
              7. Press Releases
            </p>
            <p>
              Announce your latest news or product launch with professionally
              written press releases that communicate your message clearly to
              media outlets and your audience.
            </p>
            <p className="section-title text-secondary fs-0.9">
              8. Case Studies and Whitepapers
            </p>
            <p>
              Showcase your success stories and thought leadership with in-depth
              case studies and whitepapers that highlight your expertise and
              build trust with potential clients.
            </p>
            <p>
              Let us help you tell your story with powerful, persuasive content
              that connects with your audience and drives results. Contact us
              today to discuss your content writing needs!
            </p>
          </section>
        </div>
       
      </div>
    </>
  );
};

export default ContentWriting;
